<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      'Campaign': 'Campanha',
      'Name': 'Nome',
      'Contacts': 'Contatos',
      'Cellphone': 'Celular',
      'Date': 'Data',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Campaign': 'Campanha',
      'Name': 'Nome',
      'Contacts': 'Contatos',
      'Cellphone': 'Celular',
      'Date': 'Data',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: {
    Layout,
    VclList,
    Stat,
  },
  data() {
    return {
      table: {
        body: null,
        loading: true,
        errored: false,
        empty: true,
      },

      statData: null,
    };
  },
  methods: {
    getList() {
      this.table.body = null

      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('crm/deals')
        .then(response => {
          if (response.data.status=='success') {
            this.table.body = response.data.list
            this.statData = [
              {
                icon: "bx bx-user",
                title: "Total",
                value: response.data.list.length
              }
            ]
          }
        })
        .catch(error => {
          this.table.errored = error
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body=='' || this.table.body==null) {
            this.table.empty = true
          }
        })
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Lista Qualificada') }}</h4>
          <div class="page-title-right">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table">
                <thead class="thead-light">
                  <tr>
                    <th>{{ t('Name') }}</th>
                    <th>{{ t('Campaign') }}</th>
                    <th>{{ t('Contacts') }}</th>
                    <th></th>
                    <th>{{ t('Date') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.body" :key="index">
                    <td class="notranslate">{{ td.contact.name }}</td>
                    <td class="notranslate">{{ td.campaign.name }}</td>
                    <td class="notranslate">
                      <span class="text-truncate"><i class="bx bx-mail-send font-size-18 align-middle"></i> {{ td.contact.email }}</span>
                      <i class="bx bxl-whatsapp font-size-18 align-middle"></i> {{ td.contact.cellphone }}
                    </td>
                    <td class="notranslate"><a class="whatsapp" :href="td.contact.whatsapp"><i class="bx bxl-whatsapp font-size-22 align-middle"></i> Abrir</a></td>
                    <td class="notranslate font-size-13"><i class="bx bx-time font-size-16 align-top"></i> {{ td.date }}</td>
                    <td class="notranslate"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
td {
  line-height: 15px;
  height: 70px;
  vertical-align: middle;
}
.text-truncate {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 10px;
  text-overflow: ellipsis;
  width: calc(100vw * 0.2);
}
.whatsapp {
  display: block;
  border-radius: 3px;
  padding: 5px 5px 2px;
  width: 75px;
  color: #333;
  text-align: center;
}
.whatsapp i {
  margin-bottom: 4px;
}
.whatsapp:hover {
  background: #eee;
}
</style>